import React from 'react'

const PrivacyPolicy = () => {
  // Limit these to four!
  const mockUsers = [
    {
      name: 'John Dope',
      image: null,
    },
    {
      name: 'Kya Jimenez',
      image: null,
    },
    {
      name: 'Eddison Young',
      image: null,
    },
    {
      name: 'Hakim Corbett',
      image: null,
    },
  ]

  return (
    <section>
      <h1 className='center-text'>Privacy Policy </h1>
        <p>This Privacy Policy discloses our privacy practices and the possible uses of the information that we gather via from our mobile application. By using the Site and Services you consent to the terms of this Privacy Policy. If you do not agree to the terms and conditions of this Privacy Policy, including having your personally identifiable information ("Personal Information" as defined below) used in any of the ways described in this Privacy Policy, you may not be able to use certain parts or features of our Site and Services, and in some instances, this may necessitate the revocation of your membership. CNU may update this Privacy Policy from time-to-time in our sole discretion. It is your responsibility to review the Privacy Policy for any changes each time that you use the Site and Services and you are bound by such changes. Your use of the Site and Services following us posting a new privacy policy on our Site signifies that you agree to and accept the Privacy Policy as modified.
          This Privacy Policy applies to the entire CNU family of products and services. This Privacy Policy is part of our CNU User Agreement, Terms of Use, and all other terms of using our Site and Services</p>
        <h2>Information Collected</h2>
        <p>
          CNU collects information on our users in various ways, such as, by your voluntary submissions, participation in services provided via the Site and Services, and Aggregate Information. CNU collects the following information:
          <ul>
            <li>
              <h2>1. Personal Information</h2>
              Personal Information" refers to information that tells us specifically who you are, such as your name, phone number, email, postal address, and possibly information relating to the service you provide, past work, websites you may own and run, ambitions, and equipment. In many cases, we need this information to provide the full range of services available to you while using our mobile application.
            </li>
            <li>
              <h2>2. Cellular Phone Number
          </h2>
          By providing a cellular number you give CNU consent to allow user whom may want to connect to You a means to message (sms text) or call You in efforts to use a service You provide. The ability to only be contact by message is available in Your personal settings.
            </li>
            <li>
              <h2>3. Submission of Reviews, Rating, an Comments</h2>
              CNU collects information that you voluntarily provide as part of our Site and Services, including messages, posts, comments, responses, reviews, and ratings you submit through the Site and Services, ("User Generated Content"). CNU may in our sole discretion share your User Generated Content with others such as your business reviews. If you do not want certain information shared with others, you should not submit User Generated Content, or should not submit User Generated Content that contains information or Personal Information you do not want shared in this manner. Once You have submitted User Generated Content, we reserve the right to publish it in any medium to others.
            </li>
            <li>
              <h2>4. Aggregate Information</h2>
              CNU may collect general, non-personal, statistical information about the use of the Site and Services, such as how many visitors visit a specific page on the Site, how long they stay on that page, and which hyperlinks, if any, they click on. This information represents a generic overview of our users, including their collective viewing habits, and allows us and other third parties to modify information, promotions, offers and/or discounts on products and services based on user traffic and behavior. We collect this information through the use of technologies such as 'cookies' and web beacons, which are discussed in greater detail below. We collect this information in order to help enhance the Site and Services to make for a better visitor experience.
            </li>
          </ul>
          </p>
          <h2>Use of Information</h2>
                  <p>The information CNU collects is used in a variety of ways, including
          </p>
          <ul>
            <li>for internal review;</li>
            <li>to improve the Site and Services;</li>
            <li>to verify the legitimacy of reviews and ratings;</li>
            <li>to notify you about updates to the Site and Services;</li>
            <li>to let you know about products, services, and promotions that you may be interested in;</li>
            <li>for our marketing purposes and the marketing of discounts offered through our Site and Services;</li>
            <li>to fulfill and provide products and services, including personalized or enhanced services, requested by you; and</li>
            <li>internal business analysis or other business purposes consistent with our mission; and to carry out other purposes that are disclosed to you and to which you consent.</li>
          </ul>
          <h2>Data Security, Integrity, And Access</h2>
            <p>The Personal Information you provide and we collect is stored within databases that we control directly or through our Administrative Service Providers. As we deem appropriate and as required by the applicable law, we use security measures appropriate for the sensitivity of the Personal Information and consistent with industry standards, including physical, organizational and technological protections, such as firewalls, to protect your Personal Information. However, since no security system is impenetrable, we cannot guarantee the security of our databases, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Personal Information may only be accessed by persons within our organization. We retain Personal Information that we collect only as long as reasonably necessary to fulfill the purposes for which it was collected or to otherwise meet any legal requirements.

            Your information may be transferred to, and maintained on, computers located outside of your state, province/territory, or country where the privacy laws may not be as protective as those where you live. If you are located outside the United States and choose to provide information to us, please be aware that we transfer Personal Information to the United States and process and store it there. As a result, this information may be subject to access requests from governments, courts, law enforcement officials and national security authorities in the United States according to its laws. Subject to the applicable laws, we will use reasonable efforts to ensure that appropriate protections are in place to maintain protections on the Personal Information. By submitting your Personal Information, you consent to having

            your personal data transferred to, processed, and stored in the United States.</p>
          <h2>Childrens Privacy</h2>
            <p>We do not seek or knowingly collect any Personal Information about children under the age of majority. If we become aware that we have unknowingly collected Personal Information from a child under the age of majority, we will make commercially reasonable efforts to delete such information from our database. If you are the parent or guardian of a minor child who has provided us with Personal Information, you may contact us using the information below to request it be deleted.</p>
    </section>
  )
}

export default PrivacyPolicy
