import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

// Sections
import PrivacyPolicy from '../components/legal-pages/privacy'

const Story = () => {
  return (
    <Layout>
      <SEO title="Creatives Near You | Our Story" />
      <PrivacyPolicy />
    </Layout>
  )
}

export default Story